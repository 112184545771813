export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'User', path: '/userlist', icon: <i class="fa fa-user" aria-hidden="true"></i> },
  { name: 'Record Incidents', path: '/record-list', icon: <i class="fa fa-bus" aria-hidden="true"></i> },
  { name: 'Record Perception', path: '/userperception', icon: <i class="fa fa-star-half-o" aria-hidden="true"></i> },
  { name: 'FAQ', path: '/faqlist', icon: <i class="fa fa-question-circle" aria-hidden="true"></i> },
  { name: 'Notification', path: '/notification', icon: "notifications" },
  // { name: 'Icons', path: '/material/icons', iconText: 'I' },

];
