import { _post, _get, _postCncrypt, } from '../Apimethod'


export const getPerception = async (value = '', params, activeValue = '',) => {
    let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
    const response = await _post(`/admin/list_incident?incident_type=Perception&page=${params.page}&limit=${params.limit}&user_type=${value}&credential=${params.credential}&status=${activeValue}`, authToken, authToken)
    return response
}

export const getPerceptionDate = async (start_date, end_date, params) => {
    let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
    const response = await _post(`/admin/list_incident?incident_type=Perception&page=${params.page}&limit=${params.limit}&start_date=${start_date}&end_date=${end_date}`, authToken, authToken)
    return response
}

export const getperceptionList = async (value = '', params) => {
    let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
    const response = await _post(`/admin/list_incident?incident_type=Perception&page=${params.page}&limit=${params.limit}&credential=${params.credential}`, authToken, authToken)
    return response
}

export const viewPerception = async (id) => {
    let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ''
    const response = await _get(`incident/view_incident?_id=${id}`, authToken)
    return response
}