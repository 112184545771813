import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const FaqList = Loadable(lazy(() => import('./FaqList')));
// const GetFaqtitle= Loadable(lazy(()=> import ("./GetFaqtitle")))

const createRoutes = [
  {
    path: '/faqlist',
    element: <FaqList />,
    auth: authRoles.guest,
  },
  // {
  //     path:"/getfaqtitle:id",
  //     element:<GetFaqtitle/>,
  //     auth: authRoles.guest,
  // },
];

export default createRoutes;
