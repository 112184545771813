import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  TextField,
} from '@mui/material';
import { csvOTP, csvSecretKey, getSecretKey } from 'api/Service/CsvService';
import { getuser, patchUser1 } from 'api/Service/UserService';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import React, { useEffect } from 'react';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Settings = () => {
  const [state, setState] = useState([]);
  const [modal, setModal] = useState(false);
  const [otp, setOtp] = useState([]);
  const [key, setKey1] = useState([]);
  const [getKey, setGetKey] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const _id = JSON.parse(localStorage.getItem('accessToken'))?.response?._id;



  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const getTarget = async () => {
    await getuser(_id).then((res) => {
      if (res.status === true) {
        setState(res.response[0]);
      }
    });
  };



  useEffect(() => {
    getTarget();
    SecretKey();
  }, []);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleSubmitKey = async () => {
    await csvSecretKey(key).then((res) => {
      if (res.data.status === true) {
        getSecretKey()
        toast.success(res.data.message);
        setKey1(res.data);
      }
    });
  };

  const SecretKey = async () => {
    await getSecretKey().then((res) => {
      setGetKey(res?.data?.data);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const obj = {
      target: Number(state.target)
    }

    await patchUser1(obj).then((res) => {
      if (res.status === true) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Container>
      <SimpleCard title={'Settings'}>
        <Grid container spacing={2}>
          <Grid item lg={5.7} md={6} sm={12} xs={6} sx={{ mt: 2 }}>
            <TextField
              style={{ textTransform: 'capitalize !important' }}
              fullWidth
              type="tel"
              label="Record Target"
              name="target"
              id="standard-basic"
              value={state?.target || ''}
              className="fw-bold ms-auto"
              onChange={(e) => handleChange(e)}
              sx={{ mb: 3 }}
            />
          </Grid>
          <div className="d-flex">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              sx={{ mt: 5 }}
              style={{ height: '40px', marginLeft: '50px' }}
            >
              {/* <Icon>send</Icon> */}
              <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Submit</Span>
            </Button>
          </div>

          <Grid item lg={5.7} md={6} sm={12} xs={6} sx={{ mt: 2 }}>
            <div className="d-flex">
              <TextField
                style={{ textTransform: 'capitalize !important' }}
                fullWidth
                type="text"
                label="Secret Key"
                name="Secret Key"
                id="Blur"
                className="fw-bold ms-auto"
                value={getKey?.sheet_secret || ''}
                sx={{ mb: 3 }}
              />

              <CopyToClipboard text={getKey?.sheet_secret} onCopy={onCopyText}>
                <span>
                  {isCopied ? (
                    <Icon
                      className="pointsss"
                      style={{
                        fontSize: '30px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        color: '#1976d2',
                        cursor: 'pointer',
                      }}
                    >
                      content_copy
                    </Icon>
                  ) : (
                    <Icon
                      style={{
                        fontSize: '30px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      content_copy
                    </Icon>
                  )}
                </span>
              </CopyToClipboard>
            </div>
          </Grid>

          <div className="d-flex">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{ mt: 5 }}
              style={{ height: '40px', marginLeft: '50px' }}
              onClick={handleSubmitKey}
            >
              <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Generate New Key</Span>
            </Button>
          </div>
        </Grid>
      </SimpleCard>
    </Container>
  );
};

export default Settings;
