
import AES from 'crypto-js/aes.js';
import UTF8 from 'crypto-js/enc-utf8.js';
import ECB from 'crypto-js/mode-ecb.js';
import { v4 as uuidv4 } from 'uuid';
import { values } from 'lodash';
import crypto from 'crypto-js';
import Utf8 from 'crypto-js/enc-utf8.js';


const IV_BYTE = 16; // Default IV in bytes
const SYMBOLS = '~!@#$%^&*()_+-={}[]|:;<>,.?';
const DATA = '';

const jumbleKey = (string) => {
  const output = [];
  for (let i = 0; i < string.length; i += 1) {
    if (i % 2 === 0) {
      output.push(string[i]);
    } else {
      output.unshift(string[i]);
    }
  }

  const jumbled = output.join('');
  let reversed = '';
  for (let i = jumbled.length - 1; i >= 0; i -= 1) {
    reversed += jumbled[i];
  }
  return reversed;
};

const prepareKey = (fromString) => {
  let randomized = '';
  for (let i = 0; i < fromString.length; i += 1) {
    if (Number.isNaN(+fromString[i])) {
      randomized += fromString[i];
    } else {
      switch (+fromString[i]) {
        case 1:
          randomized += `${SYMBOLS[1]}${+fromString[i] * 9}${SYMBOLS[SYMBOLS.length - 1]}`;
          break;

        case 2:
          randomized += `${SYMBOLS[2]}${+fromString[i] * 8}${SYMBOLS[SYMBOLS.length - 2]}`;
          break;

        case 3:
          randomized += `${SYMBOLS[3]}${+fromString[i] * 7}${SYMBOLS[SYMBOLS.length - 3]}`;
          break;

        case 4:
          randomized += `${SYMBOLS[4]}${+fromString[i] * 6}${SYMBOLS[SYMBOLS.length - 4]}`;
          break;

        case 5:
          randomized += `${SYMBOLS[5]}${+fromString[i] * 5}${SYMBOLS[SYMBOLS.length - 5]}`;
          break;

        case 6:
          randomized += `${SYMBOLS[6]}${+fromString[i] * 4}${SYMBOLS[SYMBOLS.length - 6]}`;
          break;

        case 7:
          randomized += `${SYMBOLS[7]}${+fromString[i] * 3}${SYMBOLS[SYMBOLS.length - 7]}`;
          break;

        case 8:
          randomized += `${SYMBOLS[8]}${+fromString[i] * 2}${SYMBOLS[SYMBOLS.length - 8]}`;
          break;

        case 9:
          randomized += `${SYMBOLS[9]}${+fromString[i] * 1}${SYMBOLS[SYMBOLS.length - 9]}`;
          break;

        default:
          randomized += `${SYMBOLS[10]}${+fromString[i] * 9}${SYMBOLS[0]}`;
          break;
      }
    }
  }

  const outputKey = jumbleKey(randomized);
  return outputKey;
};

const createInductionVector = (string) => {
  const output = [];
  for (let i = 1; i <= IV_BYTE; i += 1) {
    if (i % 2 === 0) {
      output.push(string[i]);
    } else {
      output.unshift(string[i]);
    }
  }

  const jumbled = output.join('');
  let reversed = '';
  for (let i = jumbled.length - 1; i >= 0; i -= 1) {
    reversed += jumbled[i];
  }
  return reversed;
};

const EscapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const ReverseString = (string) => {
  let stringData = '';

  if (DATA && typeof DATA === 'string') {
    stringData = DATA;
  } else if (string && typeof string === 'string') {
    stringData = string;
  } else {
    stringData = null;
  }

  let reversed = '';

  if (stringData) {
    for (let i = stringData.length - 1; i >= 0; i -= 1) {
      reversed += stringData[i];
    }
  }

  return reversed;
};

const CreateClientKey = (keyType = 'UUID_HEX', customString = null) => {
  let randomized = '';
  let fromString = '';

  if (!customString) {
    if (keyType === 'UUID_HEX') {
      fromString = uuidv4().replace(new RegExp(EscapeRegExp('-'), 'g'), '');
    } else {
      // fromString = new Types.ObjectId().toString();
    }
  } else {
    fromString = customString;
  }

  for (let i = 0; i < fromString.length; i += 1) {
    if (Number.isNaN(+fromString[i])) {
      randomized += fromString[i];
    } else {
      switch (+fromString[i]) {
        case 1:
          randomized += `${SYMBOLS[1]}${+fromString[i] * 9}${SYMBOLS[SYMBOLS.length - 1]}`;
          break;

        case 2:
          randomized += `${SYMBOLS[2]}${+fromString[i] * 8}${SYMBOLS[SYMBOLS.length - 2]}`;
          break;

        case 3:
          randomized += `${SYMBOLS[3]}${+fromString[i] * 7}${SYMBOLS[SYMBOLS.length - 3]}`;
          break;

        case 4:
          randomized += `${SYMBOLS[4]}${+fromString[i] * 6}${SYMBOLS[SYMBOLS.length - 4]}`;
          break;

        case 5:
          randomized += `${SYMBOLS[5]}${+fromString[i] * 5}${SYMBOLS[SYMBOLS.length - 5]}`;
          break;

        case 6:
          randomized += `${SYMBOLS[6]}${+fromString[i] * 4}${SYMBOLS[SYMBOLS.length - 6]}`;
          break;

        case 7:
          randomized += `${SYMBOLS[7]}${+fromString[i] * 3}${SYMBOLS[SYMBOLS.length - 7]}`;
          break;

        case 8:
          randomized += `${SYMBOLS[8]}${+fromString[i] * 2}${SYMBOLS[SYMBOLS.length - 8]}`;
          break;

        case 9:
          randomized += `${SYMBOLS[9]}${+fromString[i] * 1}${SYMBOLS[SYMBOLS.length - 9]}`;
          break;

        default:
          randomized += `${SYMBOLS[10]}${+fromString[i] * 9}${SYMBOLS[0]}`;
          break;
      }
    }
  }

  const outputKey = jumbleKey(randomized);

  let encryption_key;
  return {
    main_key: `${ReverseString(fromString)}`,
    encryption_key: outputKey,
  };
};

const getUuid = () => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  return authToken ? authToken._id : '';
};
export const encryptClientData = (dataToEncrypt) => {
  let encryption_key;
  const encDetails = {
    encryptionType: 'OBJECT',
    keyType: 'UUID_HEX',
    customKey: getUuid(),
  };
  let response;
  try {
    let stringified;

    if (encDetails.encryptionType === 'OBJECT' && typeof dataToEncrypt === 'object') {
      // Pass only JS Objects
      const requestObject = dataToEncrypt;
      stringified = JSON.stringify(requestObject);
    } else if (encDetails.encryptionType === 'STRING' && typeof dataToEncrypt !== 'object') {
      stringified = dataToEncrypt;
    } else if (typeof dataToEncrypt === 'string') {
      stringified = dataToEncrypt;
    } else if (typeof dataToEncrypt === 'number') {
      stringified = String(dataToEncrypt);
    } else {
      stringified = '';
    }

    if (stringified) {
      const randomKey = CreateClientKey(encDetails.keyType, encDetails.customKey);


      const preparedKey = prepareKey(`CLIENT${randomKey.encryption_key}CLIENT`);

      let key = '';

      for (let i = 0; i < 50; i += 1) {
        key += preparedKey[i];
      }


      const cipher = AES.encrypt(stringified, key, {
        iv: createInductionVector(key),
        mode: ECB,
      });


      const cipheredData = {
        cipher: cipher.toString(),
        data_id: randomKey.main_key,
      };

      response = cipheredData;
    } else {
      response = false;
    }
  } catch (error) {
    console.log('error..', error);
    response = false;
  }


  return response;
};

export const decryptClientData = (cipherObject) => {
  if (!cipherObject?.cipher && !cipherObject?.data_id) {
    throw new Error('Malformed Cipher Object');
  }
  const createMainKey = ReverseString(cipherObject.data_id);


  const decryptionKey = `SERVER${CreateClientKey('UUID_HEX', createMainKey).encryption_key}SERVER`;

  const preparedKey = prepareKey(decryptionKey);


  let key = '';

  for (let i = 0; i < 50; i += 1) {
    key += preparedKey[i];
  }

  const ENC_DATA = cipherObject?.cipher;


  const decipher = AES.decrypt(ENC_DATA, key, {
    iv: createInductionVector(key),
    mode: ECB,
  });


  let decipheredData;
  if (typeof JSON.parse(JSON.stringify(decipher.toString(UTF8))) === 'object') {
    decipheredData = JSON.parse(decipher.toString(UTF8));
  } else {
    decipheredData = decipher.toString(UTF8);
  }

  return decipheredData;
};

export const DecryptDBData = (cipher, uniqueId, cipherType = 'STRING') => {

  const preparedKey = prepareKey(uniqueId);

  let key = '';

  for (let i = 0; i < 50; i += 1) {
    key += preparedKey[i];
  }

  const ENC_DATA = cipher;

  const decipher = AES.decrypt(ENC_DATA, key, {
    iv: createInductionVector(key),
    mode: ECB,
  });

  let output;


  if (cipherType === 'STRING') {
    output = decipher.toString(UTF8);
  } else {
    output = JSON.parse(decipher.toString(UTF8));
  }

  return output;
};


