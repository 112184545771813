import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Settings from '../Settings/Settings';


const UserList = Loadable(lazy(() => import('./UserList')));
const EditUser = Loadable(lazy(() => import('./EditUser')));
const AddUser = Loadable(lazy(() => import('./AddUser')));

const UserProfile = Loadable(lazy(() => import('./UserProfile')));
const DetailUser = Loadable(lazy(() => import('./DetailUser')));

const userRoutes = [
  {
    path: '/userlist',
    element: <UserList />,
    auth: authRoles.guest,
  },
  {
    path: '/edituser',
    element: <EditUser />,
    auth: authRoles.guest,
  },
  {
    path: '/adduser',
    element: <AddUser />,
    auth: authRoles.guest,
  },

  {
    path: '/userprofile',
    element: <UserProfile />,
    auth: authRoles.guest,
  },

  {
    path: '/userdetails',
    element: <DetailUser />,
    auth: authRoles.guest,
  },
  { path: '/settings', element: <Settings /> },

];

export default userRoutes;
