import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import Settings from '../Settings/Settings';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const Otp = Loadable(lazy(() => import('./Otp')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));
const UpdatePassword = Loadable(lazy(() => import('./UpdatePassword')));
const VerifyEmail = Loadable(lazy(() => import('./VerifyEmail')));
const PrivacyPolicy = Loadable(lazy(() => import('./PrivacyPolicy/PrivacyPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('./PrivacyPolicy/TermsAndConditions')));
// const Settings = Loadable(lazy(() => import('./Settings')));


const sessionRoutes = [
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/updatepassword', element: <UpdatePassword /> },
  { path: '/verifyotp', element: <Otp /> },
  { path: '/verifyemail', element: <VerifyEmail /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/terms-and-conditions', element: <TermsAndConditions /> },

  { path: '/session/404', element: <NotFound /> },
];

export default sessionRoutes;
