import useSettings from 'app/hooks/useSettings';

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <>
      <img src="/assets/images/GBV_logo/newlogo.png" alt="" style={{ width: '30%' }} />
    </>
  );
};

export default MatxLogo;
