import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const NotificationList = Loadable(lazy(() => import('./notificationList')));
const EditNotification = Loadable(lazy(() => import('./EditNotification')));
const AddNotification = Loadable(lazy(() => import('./AddNotification')));

const NotificationRoutes = [
  {
    path: '/notification',
    element: <NotificationList />,
    auth: authRoles.guest,
  },
  {
    path: '/editnotification/:id',
    element: <EditNotification />,
    auth: authRoles.guest,
  },

  {
    path: '/addnotification',
    element: <AddNotification />,
    auth: authRoles.guest,
  },
];

export default NotificationRoutes;
