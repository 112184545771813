import { User } from '@auth0/auth0-spa-js';
import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import userRoutes from './views/Usermanagment/User';
import createRoutes from './views/Faqmanegment/FAQ';
import perceptionRoutes from './views/PerceptionReacord/Perception'
import RecordRoute from './views/Record_incident/RecordRoute';
import NotificationRoutes from './views/NotificationManegment/NotificationRoute';


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...materialRoutes, ...userRoutes
      , ...createRoutes, ...perceptionRoutes, ...RecordRoute, ...NotificationRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
