
import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";


const Record_Incident = Loadable(lazy(() => import("./Record_Incident")))
const WettnessIncident = Loadable(lazy(() => import("./Wettness_incident")))
const VictimIncident = Loadable(lazy(() => import("./Victim_incident")))
const ActionView = Loadable(lazy(() => import("./ViewAction")))


const RecordRoute = [

    {
        path: "/record-list",
        element: <Record_Incident />,
        auth: authRoles.guest,
    },


    {
        path: "/witness-incident-list",
        element: <WettnessIncident />,
        auth: authRoles.guest,
    },


    {
        path: "/VictimIncident",
        element: <VictimIncident />,
        auth: authRoles.guest,
    },

    {
        path: "/record-incidents-av",
        element: <ActionView />,
        auth: authRoles.guest,
    },


];

export default RecordRoute;