import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import ActionView from "../PerceptionReacord/ViewAction.js";

const UserListPerception = Loadable(lazy(() => import("./UserListPerception")));

const perceptionRoutes = [
    {
        path: "/userperception",
        element: <UserListPerception />,
        auth: authRoles.guest,
    },

    {
        path: "/Perception-av",
        element: <ActionView />,
        auth: authRoles.guest,
    },
];

export default perceptionRoutes;