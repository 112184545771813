import axios from 'axios'
import { _post, _get, _getSearch, _postCncrypt, _getSearchEncrypt, _delete, _search, _patchAuth, _getEncrypt, _patchENC, _patch } from '../Apimethod'


export const getuserList = async (value = '', params) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _getSearch(`/admin/users?user_type=${value}`, { ...params }, authToken)
  return response.data
}

export const sortUsers = async (start_date, end_date, params) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/users?start_date=${start_date}&end_date=${end_date}&page=${params.page}&limit=${params.limit} `, authToken)
  return response
}

export const sortUsersCSV = async (start_date, end_date) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : '';
  const response = await _get(
    `/admin/users?start_date=${start_date}&end_date=${end_date} `,
    authToken
  );
  return response;
};

export const getsearchUser = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _search(`/admin/search_user?credential=${value}`, authToken)
  return response.data
}

export const gettypeUser = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/search_paid_unpaid?user_type=${value}`, authToken)
  return response.data
}

export const deleteUser = async (id) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const data = await _patch(`/admin/remove_user`, id, authToken)
  return data;
}

// export const patchUser = async (values) => {
//   let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
//   const response = await axios.patch(`/admin/update_user`, values, { headers: { "Authorization": `${authToken}` } })
//   return response.data
// }

export const ActiveUser = async (id) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const data = await _post(`/admin/update_status`, id, authToken)
  return data;
}


export const getuser = async (_id) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/profile?_id=${_id}`, authToken)
  return response.data
}

export const userGroup = async () => {
  const response = await _get(`/group/list`)
  return response
}

export const patchUser = async (values) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await axios.patch(`/admin/update_user`, values, { headers: { "Authorization": `${authToken}` } })
  return response.data
}

export const Add_User = async (data,) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/admin/create_user`, data, authToken)
  return response
};

export const patchUser1 = async (values) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _patchAuth(`/admin/update_user`, values, authToken)
  return response.data
}


// ------------------------------------ encryption and decryption -----------------------------------


export const getuserListDec = async (value = '', params) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response : '';
  const response = await _getSearchEncrypt(`/admin/encrypt_users`, { ...params }, authToken?.token);

  return response;
};

export const ViewUserDec = async (_id) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _getEncrypt(`/admin/profile_encrypted?_id=${_id}`, authToken)
  return response
}

export const UpdateUser = async (values) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _patchENC(`/admin/enc_update_user`, values, authToken)
  return response
}
export const AddUserDec = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : '';
  const response = await _postCncrypt(`/admin/create_encrypt_user`, data, authToken)
  return response
};
