import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));


const materialRoutes = [
  {
    path: '/material/icons',
    element: <AppIcon />,
  },
];

export default materialRoutes;
