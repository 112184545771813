import React, { useEffect, useState } from 'react';
import { viewPerception } from 'api/Service/PerceptionService';
import { Container, Stack } from 'react-bootstrap';
import { Breadcrumb, SimpleCard } from 'app/components';
import { useLocation } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { toast } from 'react-toastify';

const MainContainer = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const ActionView = ({ route, navigation }) => {
  
  const [qaAns, setQA] = useState([]);
  const { state } = useLocation();
  const getIncident = async () => {
    await viewPerception(state.Id)
      .then((res) => {
        const Questions = JSON.parse(res.data.data.question_answer);
        setQA(Questions);
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    getIncident();
  }, []);

  return (
    <>
      <MainContainer>
        <Box className="breadcrumb">
          <Breadcrumb
            routeSegments={[
              { path: '/userperception', name: 'perception list' },
              { path: '/dashboard/default', name: 'View Q&A' },
            ]}
          />
        </Box>
      </MainContainer>
      <Container>
        <Stack spacing={6}>
          <SimpleCard>
            <div class="container-fluid">
              <div class="row">
                {qaAns.map((element, index) => {
                  return (
                    <>
                      <div class="card col-md-6 col-lg-6 col-xl-6  mb-3 ">
                        <div class="card-body">
                          <h5 class="card-title">
                            Q{index + 1} : {element.question}{' '}
                          </h5>
                          {Array.isArray(element.answer) && element.answer.length > 0 ? (
                            element.answer.map((item, key) => {
                              return (
                                <>
                                  {typeof item === 'string' && (
                                    <p class="card-text" key={key}>
                                      {key + 1}. {item}
                                    </p>
                                  )}
                                  {typeof item === 'object' && (
                                    <p class="card-text" key={key}>
                                      {key + 1}. {item.person} (
                                      {item?.gender || 'gender not defind'})
                                    </p>
                                  )}
                                </>
                              );
                            })
                          ) : // typeof element.answer === 'string' && element.answer !== '' ? (

                          element?.answer !== '' ? (
                            <p class="card-text">{element?.answer}</p>
                          ) : element?.subQuestion ? (
                            <>
                              {' '}
                              {element?.subQuestion &&
                                element.subQuestion?.map((item, index) => {
                                  // var serial_no = 0;
                                  if (item.check) {
                                    // serial_no
                                    return (
                                      <>
                                        <p key={index}>{`Q ${index + 1} - ${item.question}`}</p>

                                        <p className="mt-3">Answer - {item?.answer || 'N/A'}</p>
                                      </>
                                    );
                                  }
                                })}{' '}
                            </>
                          ) : (
                            <p class="card-text">N/A</p>
                          )}
                          {element?.fullAddress}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </SimpleCard>
        </Stack>
      </Container>
    </>
  );
};
export default ActionView;
