import { _post, _get, } from '../Apimethod'

export const csvGetOTP = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/admin/email-access`, data, authToken)
  return response
}

export const csvPostOTP = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/admin/verify_otp`, data, authToken,)
  return response
}

export const csvSecretKey = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/sheet/refresh-secret`, data, authToken)
  return response
}
export const getSecretKey = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/sheet/secret-token`, authToken)
  return response
}

export const CsvDowanloadData = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/sheet/refresh-secret`, data, authToken)
  return response
}



export const csvOTP = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/sheet/sheet-otp`, data, authToken,)
  return response
}


export const csvData = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/admin/list_incident?incident_type=Record&user_type=${value}`, authToken,)
  return response
}

export const csvDataVictim = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/list_incident?incident_type=Record&record_type=Victim&user_type=${value}`, authToken,)
  return response
}

export const csvDataWitness = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/list_incident?incident_type=Record&record_type=Witness&user_type=${value}`, authToken,)
  return response
}

export const exportPerception = async (value) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _get(`/admin/list_incident?incident_type=Perception&user_type=${value}`, authToken)
  return response
}


// to upload bulk user 
export const bulkUpload = async (data) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/admin/upload_user_sheet`, data, authToken)
  return response
}