import axios from 'axios.js';
import { decryptClientData, encryptClientData, DecryptProfileData, DecryptDBData } from './Service/Encryption';




////////////////////////////////////////////// ENC API /////////////////////////////////////////////////////////


export const _post = (url, data = {}, token) => axios.post(url, data, { headers: { "Authorization": `${token}` } });

export const _postCncrypt = async (url, data = {}, token) => {
  const encrypt = await encryptClientData(data);

  const res = await axios.post(url, encrypt, { headers: { "Authorization": `${token}` } });

  const decryptData = await decryptClientData(res.data);
  return decryptData;
};


export const _getSearchEncrypt = async (url, data = {}, token) => {

  const res = await axios.get(url, { params: data, headers: { "Authorization": `${token}` } })
  const profileData = await decryptClientData(res.data)

  return profileData
}

export const _getEncrypt = async (url, token) => {

  const res = await axios.get(url, { headers: { Authorization: `${token}` } });
  const ProfileData = await decryptClientData(res.data)

  return ProfileData
}


export const _postAuth = async (url, data = {}, token) => {
  const encrypt = await encryptClientData(data);

  const res = await axios.post(url, encrypt);

  const decryptData = await decryptClientData(res.data);

  return decryptData;
};

export const _patchENC = async (url, data, token) => {

  const encrypt = await encryptClientData(data)

  const res = await axios.patch(url, encrypt, { headers: { Authorization: `${token}` } });

  const decryptData = await decryptClientData(res.data)

  return decryptData

}




////////////////////////////////////////////// Local API /////////////////////////////////////////////////////////





export const _get = (url, token) => axios.get(url, { headers: { Authorization: `${token}` } });

export const _getSearch = (url, data = {}, token) => axios.get(url, { params: data, headers: { "Authorization": `${token}` } })

export const _patch = (url, data, token) =>
  axios.patch(url, data, { headers: { Authorization: token } });

export const _patchAuth = (url, data, token) =>
  axios.patch(url, data, { headers: { Authorization: `${token}` } });

export const _put = (url, data = {}) => axios.put(url, data);

export const _putAuth = (url, data = {}, token) =>
  axios.put(url, data, { headers: { Authorization: `${token}` } });



export const _delete = async (url, data, token) => {
  const response = await axios.delete(url, {
    headers: {
      Authorization: `${token}`,
    },
    data: {
      _id: data?._id,
    },
  });
  return response;
};

export const _deleteAuth = async (url, data, token) =>
  await axios.delete(url, {
    headers: {
      Authorization: `${token}`,
    },
    data,
  });

export const _search = async (url, data, token) => {
  const response = await axios.get(url, token);
  return response;
};

export const patchUser1 = async (values) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _patchAuth(`/admin/update_user`, values, authToken?.token);
  return response.data;
};

export const putNotification = async (id, values) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _putAuth(`/group_msg/update`, { ...values, id: id }, authToken?.token);
  return response.data;
};


export const sortUsers = async (start_date, end_date, params) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _get(
    `/admin/users?start_date=${start_date}&end_date=${end_date}&page=${params.page}&limit=${params.limit} `,
    authToken?.token
  );
  return response;
};

export const sortUsersCSV = async (start_date, end_date) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _get(
    `/admin/users?start_date=${start_date}&end_date=${end_date} `,
    authToken?.token
  );
  return response;
};

export const getsearchUser = async (value) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _search(`/admin/search_user?credential=${value}`, authToken?.token);
  return response.data;
};

export const gettypeUser = async (value) => {
  let authToken = localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : '';
  const response = await _get(`/admin/search_paid_unpaid?user_type=${value}`, authToken?.token);
  return response.data;
};

export const getExcelSheetIncident = async (params) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/sheet/incident`, params, authToken, authToken)
  return response
}

export const getExcelSheetPerception = async (params) => {
  let authToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')).response.token : ""
  const response = await _post(`/sheet/perception`, params, authToken, authToken)
  return response
}

export const downloadExcel = (filename) => {
  const newEnv = process.env.REACT_APP_API_URL.split('/api')[0]
  const win = window.open(
    // 'http://52.66.8.214:8002' + filename
    newEnv + '/exports' + filename
  );
  const timer = setInterval(() => {
    if (win?.closed) {
      clearInterval(timer);
    }
  }, 500);
}