import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { _post } from 'api/Apimethod'
import { toast } from "react-toastify";
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', JSON.stringify(accessToken))
        axios.defaults.headers.common.Authorization = `${accessToken?.token}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (credential, pin) => {
        try {
            const response = await _post('/admin/', {
                credential,
                pin,
            });
            //const { accessToken, user } = response.data
            const user = response.data;
            const { status } = response.data;
            if (status === true) {
                setSession(response.data)
                toast.success(response.data.message)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                });
            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message);
        }
    };
    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            const accessToken = localStorage.getItem('accessToken') ? JSON.parse(window.localStorage.getItem('accessToken')) : null
            const user = accessToken
            try {

                if (accessToken) {
                    setSession(accessToken)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user
                        },
                    })
                }
            } catch (err) {

                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext

